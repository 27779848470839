import { faCaretDown, faCaretUp, faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useColors, useRolesForSelectedLocation } from "../../contexts/UserSettingsContext"
import { getCSSColor } from "../../helpers/ColorHelper"
import styles from "./RolesLegend.module.scss"

const RolesLegend: FC = () => {
    const { t } = useTranslation()
    const colors = useColors()
    const roles = useRolesForSelectedLocation()

    const [show, setShow] = useState(false)

    const toggleShow = useCallback(() => setShow(!show), [show, setShow])

    const items = useMemo(() => {
        return roles.map(({ id, name, color }) => {
            return {
                id: id,
                name: name,
                color: getCSSColor(colors.get(color)!),
            }
        })
    }, [roles])

    return (
        <div className="mt-3">
            <Button onClick={toggleShow} variant="link">
                <span className="me-1">{show ? t("RolesLegend.hide") : t("RolesLegend.show")}</span>
                <FontAwesomeIcon icon={show ? faCaretUp : faCaretDown} />
            </Button>
            {show ? (
                <div className="mt-3">
                    {items.map(({ id, name, color }) => (
                        <div key={id} className={styles.item}>
                            <FontAwesomeIcon icon={faCircle} color={color} className="me-2" />
                            <span className="text-bold text-muted">{name}</span>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default RolesLegend
