import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useEffect, useMemo, useRef } from "react"
import { Badge } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import ProgressBar from "../../components/ProgressBar"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { refetchInterval } from "../../helpers/QueryHelper"
import { loadOccupationTemplate } from "../../services/OccupationTemplate"
import { OccupationTemplateGenerationType, load as loadOccupationTemplateGeneration } from "../../services/OccupationTemplateGeneration"
import OccupationTemplateType from "../../types/OccupationTemplateType"

const GenerateOccupationTemplateStatusPage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const location = useSelectedLocation()
    const occupationTemplateId = useMemo(() => parseInt(params.occupationTemplateId!), [params])
    const occupationTemplateGenerationId = useMemo(() => parseInt(params.occupationTemplateGenerationId!), [params])
    const navigate = useNavigate()

    const { data: occupationTemplate } = useQuery<OccupationTemplateType>(["occupationTemplate", occupationTemplateId], loadOccupationTemplate(occupationTemplateId))
    const occupationTemplateName = useMemo(() => (occupationTemplate ? occupationTemplate.name : ""), [occupationTemplate])

    const fetchCount = useRef(0)
    const { data: generation } = useQuery<OccupationTemplateGenerationType>(
        ["shiftTemplateGenerationStatus", occupationTemplateGenerationId],
        () => loadOccupationTemplateGeneration(occupationTemplateGenerationId),
        { refetchInterval: refetchInterval(fetchCount) }
    )
    const progress = useMemo(() => (generation ? generation.progress : 0), [generation])
    const done = useMemo(() => (generation ? generation.done : false), [generation])

    useEffect(() => setActiveTab("OccupationTemplates"), [setActiveTab])
    useEffect(() => {
        if (occupationTemplate && occupationTemplate.location !== location.id) {
            navigate("/basisbezettingen")
        }
    }, [occupationTemplate])
    useEffect(() => {
        if (done) {
            navigate(`/basisbezettingen/${occupationTemplateId}`)
        }
    }, [done, occupationTemplateId])

    return (
        <RegularPage
            id="GenerateOccupationTemplateStatus"
            breadCrumbs={[
                { title: t("OccupationTemplatesPage.title_occupation_templates"), link: "/basisbezettingen" },
                { title: occupationTemplateName, link: `/basisbezettingen/${occupationTemplateId}` },
                { title: t("GenerateOccupationTemplateStatus.generate") },
            ]}
        >
            <div className="mb-4">
                <h2>{t("GenerateOccupationTemplateStatus.generate_item", { item: occupationTemplateName })}</h2>
                <Badge bg="info-light" text="dark" className="mt-4 mb-2">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                    {t("GenerateOccupationTemplateStatus.unexus_takes_time")}
                </Badge>
            </div>
            <div className="mb-4">
                <ProgressBar done={done} progress={progress} />
            </div>
        </RegularPage>
    )
}

export default GenerateOccupationTemplateStatusPage
