import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import * as yup from "yup"
import { ObjectSchema } from "yup"
import Loader from "../../../../components/Loader"
import DateRangeInput from "../../../../components/form/DateRangeInput"
import FormErrorMessages from "../../../../components/form/FormErrorMessages"
import MultiSelectFilter from "../../../../components/table/MultiSelectFilter"
import { useNestedRolesForSelectedLocation, useSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { addDays, getFirstNextWorkday } from "../../../../helpers/DaysHelper"
import { getRoleFilterOptions, onClickFilter } from "../../../../helpers/FilterHelper"
import { refetchInterval } from "../../../../helpers/QueryHelper"
import { useYupValidationResolver } from "../../../../hooks/UseYupValidationResolver"
import { createTaskForecastStatus, loadTask, TaskForecastResult, TaskType } from "../../../../services/Task"
import styles from "../Statistics.module.scss"
import ForecastGraph1 from "./ForecastGraph1"
import ForecastGraph2 from "./ForecastGraph2"

interface Inputs {
    roleUids: string[]
    fromDate: Date
    toDate: Date
}

interface ForecastProps {
    show: boolean
}

const Forecast: FC<ForecastProps> = ({ show }) => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const nestedRoles = useNestedRolesForSelectedLocation()
    const roleOptions = useMemo(() => {
        return getRoleFilterOptions(nestedRoles, false, false)
    }, [nestedRoles])

    const [taskId, setTaskId] = useState<number | undefined>()
    const fetchCount = useRef(0)
    const { data: task } = useQuery<TaskType<TaskForecastResult>>(["task", taskId], () => loadTask<TaskForecastResult>(taskId!), {
        enabled: !!taskId,
        refetchInterval: refetchInterval(fetchCount),
    })

    const initialData = useMemo<Inputs>(() => {
        const initialFrom = getFirstNextWorkday(new Date(), location.enabledDays)
        return {
            roleUids: roleOptions.filter((o) => !o.children).map((o) => o.key),
            fromDate: initialFrom,
            toDate: addDays(initialFrom, 6),
        }
    }, [location])

    const validationSchema: ObjectSchema<Inputs> = useMemo(() => {
        return yup.object({
            fromDate: yup.date().required(t("StatisticsPage.fill_start_date")),
            toDate: yup
                .date()
                .required(t("StatisticsPage.fill_end_date"))
                .when(["fromDate"], ([fromDate], schema) => {
                    const limit = addDays(fromDate, 100)
                    return schema.max(limit, t("StatisticsPage.max_100_days"))
                }),
            roleUids: yup.array().of(yup.string().required()).required().min(1, t("StatisticsPage.select_at_least_one_role")),
        })
    }, [t])

    const resolver = useYupValidationResolver(validationSchema)

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        getValues,
    } = useForm({
        defaultValues: initialData,
        resolver,
    })

    const onChangeFromDate = useCallback((newValue: Date | null) => setValue("fromDate", newValue), [setValue])
    const onChangeToDate = useCallback((newValue: Date | null) => setValue("toDate", newValue), [setValue])
    const onChangeRoleUids = useCallback((newValue: string[]) => setValue("roleUids", newValue), [setValue])

    const onSubmit = useCallback(
        (values: Inputs) => {
            createTaskForecastStatus({ location: location.id, interval: "DAY", ...values }).then((response) => {
                setTaskId(response.data.id)
            })
        },
        [location, setTaskId]
    )

    useEffect(() => {
        onSubmit(getValues())
    }, [])

    const errorMessages = useMemo(() => {
        let messages = []
        if (task && task.done && !task.success) {
            messages.push(t("Main.something_went_wrong"))
        }
        if (errors) {
            messages = messages.concat(Object.entries(errors).map(([type, error]) => error?.message as string))
        }
        return messages
    }, [task, t, errors])

    return show ? (
        <div className="row">
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className={`mt-4 ${styles.formPanel}`}>
                <DateRangeInput startDate={watch("fromDate")} setStartDate={onChangeFromDate} endDate={watch("toDate")} setEndDate={onChangeToDate} />

                <div className="filter-panel">
                    <div className="dropdown d-inline-block">
                        <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {t("Buttons.roles")}
                        </button>
                        <ul className="dropdown-menu filter-list text-start" onClick={onClickFilter}>
                            <MultiSelectFilter options={roleOptions} values={watch("roleUids")} putFilterValue={onChangeRoleUids} toggleAllEnabled={true} autoUpdateParent={false} />
                        </ul>
                    </div>
                </div>

                <Button type="submit" data-cy="submit-button">
                    {t("Buttons.generate")}
                </Button>

                {task && task.resultUrl ? (
                    <Link target="_blank" to={task.resultUrl} className="btn btn-link">
                        {t("Buttons.download")}
                    </Link>
                ) : null}

                <div className="ms-3" hidden={!task || task.done}>
                    <Loader />
                </div>
            </Form>

            {errorMessages.length > 0 ? (
                <div className="mt-2">
                    <FormErrorMessages errors={errorMessages} key={errorMessages.join()} />
                </div>
            ) : null}

            <div className="col-12">
                <div className="mt-4 p-3">
                    <div className="h5 mb-4">{t("Buttons.planned_time_and_main_occupation")}</div>
                    <div className={styles.graph}>
                        <ForecastGraph1 task={task} />
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="mt-4 p-3">
                    <div className="h5 mb-4">{t("Buttons.under_occupation_in_planning")}</div>
                    <div style={{ width: "100%", height: "10rem" }}>
                        <ForecastGraph2 task={task} />
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default Forecast
