import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import * as yup from "yup"
import DateRangeInput from "../../../../components/form/DateRangeInput"
import FormErrorMessages from "../../../../components/form/FormErrorMessages"
import Loader from "../../../../components/Loader"
import MultiSelectFilter from "../../../../components/table/MultiSelectFilter"
import { useNestedRolesForSelectedLocation, useSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { addDays, addMonths, addWeeks, getFirstMondayOfWeek } from "../../../../helpers/DaysHelper"
import { getRoleFilterOptions, onClickFilter } from "../../../../helpers/FilterHelper"
import { refetchInterval } from "../../../../helpers/QueryHelper"
import useIntervalOptions from "../../../../hooks/UseIntervalOptions"
import { useYupValidationResolver } from "../../../../hooks/UseYupValidationResolver"
import { CreateTaskRoleTimeStats, IntervalType, TaskRoleTimeResult, TaskType, createTaskRoleTimeStats, loadTask } from "../../../../services/Task"
import styles from "../Statistics.module.scss"
import RoleHoursGraph1 from "./RoleHoursGraph1"
import RoleHoursGraph2 from "./RoleHoursGraph2"
import RoleHoursGraph3 from "./RoleHoursGraph3"
import RoleHoursTable from "./RoleHoursTable"

interface RoleHoursProps {
    show: boolean
}

const RoleHours: FC<RoleHoursProps> = ({ show }) => {
    const { t } = useTranslation()
    const intervalOptions = useIntervalOptions()
    const location = useSelectedLocation()
    const nestedRoles = useNestedRolesForSelectedLocation()
    const roleOptions = useMemo(() => getRoleFilterOptions(nestedRoles, false, false), [nestedRoles])
    const [taskId, setTaskId] = useState<number | undefined>()
    const fetchCount = useRef(0)
    const { data: task } = useQuery<TaskType<TaskRoleTimeResult>>(["task", taskId], () => loadTask<TaskRoleTimeResult>(taskId!), {
        enabled: !!taskId,
        refetchInterval: refetchInterval(fetchCount),
    })

    const initialData = useMemo<CreateTaskRoleTimeStats>(() => {
        const initialFrom = getFirstMondayOfWeek(new Date())
        return {
            location: location.id,
            interval: "DAY",
            roleUids: roleOptions.filter((o) => !o.children).map((o) => o.key),
            fromDate: initialFrom,
            toDate: addDays(initialFrom, 6),
        }
    }, [location, roleOptions])

    const validationSchema = useMemo(() => {
        return yup.object({
            fromDate: yup.date().required(t("StatisticsPage.fill_start_date")),
            toDate: yup
                .date()
                .required(t("StatisticsPage.fill_end_date"))
                .when(["fromDate", "interval"], ([fromDate, interval], schema) => {
                    if (interval === "DAY") {
                        const limit = addDays(fromDate, 100)
                        return schema.max(limit, t("StatisticsPage.max_100_days"))
                    } else if (interval === "WEEK") {
                        const limit = addWeeks(fromDate, 53)
                        return schema.max(limit, t("StatisticsPage.max_53_weeks"))
                    } else if (interval === "MONTH") {
                        const limit = addMonths(fromDate, 12)
                        return schema.max(limit, t("StatisticsPage.max_12_months"))
                    }
                    return schema.optional()
                }),
            roleUids: yup.array().min(1, t("StatisticsPage.select_at_least_one_role")),
        })
    }, [t])

    const resolver = useYupValidationResolver(validationSchema)

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        getValues,
    } = useForm({
        defaultValues: initialData,
        resolver,
    })

    const onChangeInterval = useCallback((e: ChangeEvent<HTMLSelectElement>) => setValue("interval", e.target.value as IntervalType), [setValue])
    const onChangeFromDate = useCallback((newValue: Date | null) => setValue("fromDate", newValue), [setValue])
    const onChangeToDate = useCallback((newValue: Date | null) => setValue("toDate", newValue), [setValue])
    const onChangeRoleUids = useCallback((newValue: string[]) => setValue("roleUids", newValue), [setValue])

    const onSubmit = useCallback(
        (values: CreateTaskRoleTimeStats) => {
            createTaskRoleTimeStats(values).then((response) => {
                setTaskId(response.data.id)
            })
        },
        [setTaskId]
    )

    useEffect(() => {
        onSubmit(getValues())
    }, [])

    const errorMessages = useMemo(() => {
        let messages = []
        if (task && task.done && !task.success) {
            if (task.errorId === "EXPOSABLE_ERROR_NO_ACTIVE_SHIFT_TEMPLATE") {
                messages.push(t("StatisticsPage.no_shift_template_active_for_selected_date"))
            } else {
                messages.push(t("Main.something_went_wrong"))
            }
        }
        if (errors) {
            messages = messages.concat(Object.entries(errors).map(([type, error]) => error?.message as string))
        }
        return messages
    }, [task, errors])

    return show ? (
        <div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className={`mt-4 ${styles.formPanel}`}>
                <DateRangeInput startDate={watch("fromDate")} setStartDate={onChangeFromDate} endDate={watch("toDate")} setEndDate={onChangeToDate} />

                <select className="form-select form-select-inline form-control" onChange={onChangeInterval} value={watch("interval")}>
                    {intervalOptions.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </select>

                <div className="filter-panel">
                    <div className="dropdown d-inline-block">
                        <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {t("Buttons.roles")}
                        </button>
                        <ul className="dropdown-menu filter-list text-start" onClick={onClickFilter}>
                            <MultiSelectFilter options={roleOptions} values={watch("roleUids")} putFilterValue={onChangeRoleUids} toggleAllEnabled={true} autoUpdateParent={false} />
                        </ul>
                    </div>
                </div>

                <Button type="submit" data-cy="submit-button">
                    {t("Buttons.generate")}
                </Button>

                {task && task.resultUrl ? (
                    <Link target="_blank" to={task.resultUrl} className="btn btn-link">
                        {t("Buttons.download")}
                    </Link>
                ) : null}

                <div className="ms-3" hidden={!task || task.done}>
                    <Loader />
                </div>
            </Form>

            {errorMessages.length > 0 ? (
                <div className="mt-2">
                    <FormErrorMessages errors={errorMessages} key={errorMessages.join()} />
                </div>
            ) : null}

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="mt-4 p-3">
                        <div className="h5 mb-4">{t("StatisticsPage.planned_time_per_role")}</div>
                        <div className={styles.graph}>
                            <RoleHoursGraph1 task={task} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mt-4 p-3">
                        <div className="h5 mb-4">{t("StatisticsPage.planned_time_per_role")}</div>
                        <div className={styles.graph}>
                            <RoleHoursGraph2 task={task} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 p-3">
                <div className="h5">{t("StatisticsPage.planned_time_in_period")}</div>
                <div className="row">
                    <div className="col-12 col-sm-6 mt-4">
                        <RoleHoursTable task={task} />
                    </div>
                    <div className="col-12 col-sm-6 mt-4" style={{ height: `${2 + (task?.result?.roleUids.length ?? 0) * 2}rem` }}>
                        <RoleHoursGraph3 task={task} />
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default RoleHours
