import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"

interface NoDataProps {
    taskResult: any
}

const NoData: FC<NoDataProps> = ({ taskResult }) => {
    const { t } = useTranslation()

    const message = useMemo(() => {
        if (!taskResult) {
            return null
        }
        if (taskResult["roles"].length === 0) {
            return t("StatisticsPage.no_data")
        }
        return null
    }, [taskResult])

    return <div className="text-small text-muted">{message}</div>
}

export default NoData
